html {
  scroll-behavior: smooth;
}

@media screen and (max-width: 1200px) {
  .top-container {
    padding-top: 0px !important;
  }
  .main-top-container {
    padding-top: 0px !important;
  }
  .footer-bar-item {
    padding-left: 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-content-title {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 360px) {
  .bottomright {
    width: 100% !important;
    z-index: 2;
    height: 60%;
  }
  .chat-input {
    width: 100% !important;
  }
  .main-top-container {
    padding-top: 0px !important;
  }
  .atc-col-container {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
  }
  .atc-counter-container {
    text-align: center;
    padding-bottom: 30px;
  }
  .slider-container {
    padding-top: 30px !important;
  }
  .second-slider-text {
    font-size: 35px !important;
  }
  .featured-card {
    height: 470px !important;
  }
  .card-btn {
    font-size: 10px !important;
  }
  .order-history-img {
    width: 100%;
  }
  .card-img {
    height: 150px !important;
  }
  .card-content-title {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 950px) {
  .chat-input {
    width: 40% !important;
  }
  .bottomright {
    width: 40% !important;
    z-index: 2;
    height: 60%;
  }
  .top-container {
    padding-top: 0px !important;
  }
  .slider-container {
    padding-top: 0px !important;
  }
  .about-header-paragraph {
    text-align: left !important;
  }
  .profile-container {
    padding-top: 10px !important;
    padding-left: 20px !important;
  }
  .about-video-player {
    height: 150px !important;
  }
}
.slider-child-container {
  height: 580px !important;
}
@media screen and (max-width: 900px) {
  .slider-child-container {
    height: 400px !important;
  }
  .footer-main-container {
    margin-top: 10px !important;
    padding-top: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .bottomright {
    width: 60% !important;
    z-index: 2;
    height: 60%;
  }
  .slider-child-container {
    height: 250px !important;
  }
}
@media screen and (max-width: 481px) {
  .bottomright {
    width: 90% !important;
    z-index: 2;
    height: 60%;
  }
  .service-heading-text {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    color: #173470 !important;
  }
}

body {
  font-family: 'Poppins' !important;
}
.main-container {
  /* background-color: #000000 !important; */
  width: 100% !important;
  color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navbar-container {
  width: calc(100% - 270px) !important;
  height: 70px;
  position: fixed;
  z-index: 2;
  top: 0 !important;
  /* box-shadow: 0px 14px 13px 1px #000000 !important; */

  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.navbar-logo-img {
  height: 50px;
  /* margin-left: -120px; */
}
.navbar-logo-img1 {
  height: 10%;
  margin: 3%;
}
.nav-bar-item {
  list-style-type: none;
  overflow: hidden;
  color: black;
  display: flex !important;
  flex: 100%;
  /* flex-wrap: wrap; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-bar-items {
  list-style-type: none;
  color: black;
}
.nav-link a {
  color: black;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 800 !important;
}

.nav-link a:hover:not(.active) {
  text-decoration: underline !important;
  color: #4e9f0b;
}
.active {
  text-decoration: underline !important;
  color: #4e9f0b;
}
.navbar-icon {
  color: black;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  /* padding-left: 12px !important; */
}
.navbar-icon:hover {
  color: #4e9f0b;
}
.cart-icon {
  margin-top: -4px !important;
  color: rgb(0, 0, 0) !important;
}
.navbar-cart-number {
  z-index: 1;
  margin-left: 13px !important;
  font-size: 8px !important;
  background-color: #4e9f0b;
  border-radius: 50px !important;
  /* padding: 1px !important; */
  margin-top: 2px;
  color: white;
}
.icon-com {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.icon-coms {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
}
.nav-list {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  padding-top: 10px;
  /* border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px; */
  /* background-color: white; */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
}
.background-filter::after {
  -webkit-backdrop-filter: blur(5px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  backdrop-filter: blur(10px) !important; /* Supported in Chrome 76 */
  filter: blur(20px);
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.background-filter {
  position: relative;
}

.background {
  /* background-image: url("../Assets/sliderbackground.jpg");
    background-size: 100% 100%; */
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(180deg, #04102a, #132d5f, #0a1c41); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    180deg,
    #04102a,
    #132d5f,
    #0a1c41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* .slider-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-top: 130px;
    width: 100% !important;
    position: relative;
    z-index: 1;
  } */
.slider-container {
  height: 500px !important;
}
.right-arrow-btn {
  float: right;
  background-color: white !important;
  border-radius: 100% !important;
  margin-right: 20px !important;
  font-size: 5px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.left-arrow-btn {
  float: left;
  background-color: white !important;
  border-radius: 100% !important;
  margin-left: 30px !important;
  font-size: 5px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.slider-text-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.first-slider-text {
  width: 83%;
  background-color: white;
  color: #173470;
  padding: 10px !important;
  font-family: 'Poppins' !important;
  font-weight: 100 !important;
  font-size: 12px !important;
  flex-shrink: inherit;
  letter-spacing: 4px !important;
}
.second-slider-text {
  font-size: 50px;
  line-height: 1.2;
  color: white;
  font-family: 'Poppins' !important;
  font-weight: 800 !important;
  padding: 20px !important;
  padding-bottom: 5px !important;
  padding-left: 0 !important;
}
.slider-paragraph {
  color: white;
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  font-size: 200 !important;
  padding-bottom: 20px;
}
.slider-btn {
  padding-left: 5vw !important;
  padding-right: 5vw !important;
  padding-top: 1vw !important;
  padding-bottom: 1vw !important;
  text-decoration: none !important;
}
.react-swipeable-view-container {
  height: 100% !important;
}
/* .slider-card{
    height: 100%;
    padding: 5vw;
  } */
.service-header-container {
  text-align: center;
  padding-top: 30px;
}
/* .service-header-text {
    font-family: "Poppins" !important;
    font-weight: 800 !important;
    font-size: 30px !important;
    line-height: 126.5%;
    font-style: normal !important;
    color: #000000;
  }
  .service-paragraph-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 20px;
  }
  .service-paragraph-text {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: #000000;
    width: 70% !important;
  } */
.featured-products-container {
  padding: 10px;
}
.featured-products-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.featured-products-header-text {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 126.5% !important;
  /* identical to box height, or 51px */
  color: #000000;
}
.featured-products-header-link {
  text-decoration: none !important;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 34px;
  color: #58a30a;
}
.featured-products-header-link:hover {
  color: #000000;
}
.featured-card {
  /* max-width: 70vw; */
  height: 470px;
  display: flex !important;
  flex-direction: column;
  /* flex-wrap: wrap; */
  text-decoration: none;
  padding: 10px !important;
  justify-content: space-between;
}
.card-img {
  height: 250px;
}
.featured-card:hover {
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.25);
}
.category-card-container {
  /* max-width: 70vw; */
  height: 300px;
  display: flex !important;
  flex-direction: column;
  /* flex-wrap: wrap; */
  text-decoration: none;
  padding: 10px !important;
  justify-content: space-between;
}
.category-card-container:hover {
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.25);
}
.card-content-title {
  font-size: 18px;
  font-weight: bold;
}
.category-container {
  display: flex;
  justify-content: center;
  margin-bottom: -20px;
}
/* .card-img{
    height: ;
  } */

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: #4e9f0b !important;
  font-size: 30px !important;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: #0a1c41 !important;
  font-size: 30px !important;
}

.category-card {
  border: 1px solid #58a30a;
  border-radius: 55px !important;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-decoration: none;
  margin: 10px;
}
.category-card-content-title {
  font-size: 0.9rem !important;
  color: #58a30a;
  padding-left: 10px;
  text-decoration: none;
}
.category-content-card-item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  padding: 5px !important;
  align-items: center !important;
}
.category-card:hover {
  background-color: #58a30a;
}
.category-card:hover .category-card-content-title {
  color: white;
}
.client-company-text {
  text-align: center;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 126.5% !important;
  /* identical to box height, or 51px */
  color: #000000;
}

.footer-main-container {
  background-color: #000000;
  color: white;
  /* padding: 20px; */
  margin-top: 30px;
  font-family: 'Poppins';
  padding-top: 60px;
  bottom: 0;
}

.footer-main-container-contact {
  background-color: #000000;
  color: white;
  /* padding: 20px; */
  margin-top: 0px;
  font-family: 'Poppins';
  padding-top: 60px;
  bottom: 0;
}
.social-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
  gap: 10px;
}
.social-icon-item {
}
.footer-bar-item {
  overflow: hidden;
  color: white;
  list-style-type: none;
}
.footer-link {
  list-style: none;
}
.footer-link::before {
  content: '\00BB';
}
.footer-link a {
  text-align: center;
  padding: 5px 6px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 500 !important;
}

.footer-link a:hover:not(.active) {
  text-decoration: underline !important;
  color: #4e9f0b;
}
.active {
  text-decoration: underline !important;
  color: #4e9f0b;
}
.app-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  flex-wrap: wrap;
}
.app-icons img {
  width: 150px;
  padding-right: 10px;
}
.featured-products-header {
  /* background-image: url("../Assets/bg-img.png"); */
  background-repeat: no-repeat;
  padding: 120px 40px;
  /* display: flex; */
  background-size: 100% 100%;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}
.featured-products-header-title {
  font-family: 'Poppins' !important;
  /* font-size: 100px !important; */
  font-weight: 700 !important;
  letter-spacing: 10px !important;
  text-transform: uppercase;
  color: white;
}
.breadcrumb-list {
  text-decoration: none !important;
  color: white !important;
  font-weight: 600 !important;
  /* font-size: 31px !important; */
  font-family: 'Poppins' !important;
}
.dash-text {
  text-decoration: none !important;
  color: white !important;
  font-weight: 600 !important;
  /* font-size: 31px !important; */
  font-family: 'Poppins' !important;
  margin-top: 5px;
}
.dash-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-end; */
  /* margin-top: -30px; */
}
.featured-produts-containers {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about-us-image-container {
  display: flex;
  flex-direction: row;
  /* margin-top: 50px; */
  flex: 100%;
  width: 100%;
}
.about-us-image1 {
  height: 55%;
  width: 100% !important; /* width: auto; */
}
.about-us-image2 {
  height: 55%;
  width: 100% !important; /* width: auto; */
  z-index: 0.999;
  margin-top: 100px;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-header {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #58a30a;
  padding-top: 20px;
}
.about-header-title {
  font-family: 'Poppins' !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}
.about-header-paragraph {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
}
.about-video-container {
  padding-top: 20px;
  text-align: center;
}
.contact-header-title {
  font-size: 16px !important;
}
.contact-title {
  font-size: 30px !important;
}
.list-item-icon {
  background-color: #173470 !important;
  padding: 7px;
  color: white;
  font-size: 28px;
  border-radius: 50px;
}
.text-field {
  color: gray;
  padding: 5px;
}

.container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.container:hover .overlay {
  height: 40px;
}

.text {
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.careers-header-titile {
  font-size: 25px !important;
}
.careers-job-title {
  font-size: 18px !important;
  font-family: 'Poppins' !important;
  color: #173470;
  text-decoration: none;
  font-weight: 600 !important;
}
.careers-job-date {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
}
.careers-job-descr {
  color: #173470;
  font-size: 16px !important;
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
}
.career-btn {
  text-decoration: none;
  background-color: #173470;
  color: white;
  border-radius: 50px;
  padding: 5px;
  padding-left: 20px;
  float: right;
  font-family: 'Poppins' !important;
  font-weight: 300 !important;
  /* float: right; */
}
.career-btn:hover {
  background-color: #173470;
  color: white;
}
.careers-top-boder {
  border-top: 2px solid gray;
}
.careers-details-job-title {
  font-size: 30px !important;
  font-family: 'Poppins' !important;
  color: #173470;
  text-decoration: none;
  font-weight: 700 !important;
  font-style: normal !important;
  line-height: 126.5% !important;
}
.careers-details-job-date {
  font-size: 18px !important;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
}
.careers-job-desc-details {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  line-height: 25px !important;
}

.service-heading-text {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 60px !important;
  color: #173470 !important;
}
.service-header-text {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 32px !important;
  line-height: 126.5% !important;
  color: #000000;
}
.service-paragraph-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 20px;
}
.service-paragraph-text {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #000000;
  width: 70% !important;
}
.service-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.service-img {
  padding: 10px;
  height: 200px;
  width: 300px;
}
.service-paragraph-text2 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  padding-top: 20px;
}
.get-quote-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: -460px !important;
}
.get-quote-form {
  background-color: white;
}
.get-quote-input-label {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14px !important;
}
.get-quote-input {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  /* border: 1px solid black; */
  /* padding: 5px; */
}
.get-quote-select {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  border: 1px solid black !important;
  border-radius: none !important;
  padding: 5px !important;
}
.get-quote-input-label1 {
  font-size: 12px !important;
  color: white;
}
.get-quote-head-container {
  background: linear-gradient(180deg, #173470 8.07%, rgba(23, 52, 112, 0) 98.35%);
}
.get-quote-text-blur1 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 220px !important;
  line-height: 126.5% !important;
  text-align: center;
  color: #ffffff;
  filter: blur(25px);
}
.get-quote-text-blur2 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 320px !important;
  line-height: 126.5% !important;
  text-align: center;
  color: #ffffff;
  filter: blur(25px);
}
.get-quote-content-heading {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 40px !important;
  color: #173470;
}
.get-quote-content {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 18px !important;
}
.get-quote-content-container {
  padding: 20px;
}
.profile-list {
  list-style-type: none;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 14px !important;
  color: #000000;
}
.profile-list-item a {
  display: block;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
}
.profile-list-item a.active {
  color: #173470;
  font-weight: 600;
  text-decoration: none;
}
.profile-list-item a.visited {
  color: #173470;
  font-weight: 600;
  text-decoration: none;
}

.profile-list-item a:hover:not(.active) {
  color: #173470;
  font-weight: 600;
  text-decoration: none;
}
.profile-bar-name {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: #000000;
  font-weight: 700 !important;
}
.profile-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 130px;
  padding-left: 50px;
}
.prfile-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.add-phone {
  color: #4e9f0b;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 12px !important;
  cursor: pointer;
}
.add-phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-btn {
  padding-top: 150px !important;
}
.profile-content-container {
  /* background-color: #ffffff; */
  padding-top: 20px;
  padding-bottom: 50px;
}
.get-quote-input-label2 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 18px !important;
  padding-top: 10px;
}
.address-content {
  border: 1px solid black;
  border-radius: 5px;
  width: 35%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.address-profile-user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.add-btn {
  background: rgba(23, 52, 112, 0.31);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 126.5%;
  color: #173470;
  width: 45px;
  padding: 5px;
}
.order-history-card {
  background: rgba(217, 217, 217, 0.45) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  flex-wrap: wrap;
}
.order-history-card-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.order-history-card-content-des {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 101% !important;
  padding-left: 10px;
  padding-top: 5px;
}
.order-history-card-content-date {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.3);
}
.order-history-dialog-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.order-history-dialog-content-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 126.5% !important;
  /* or 30px */
  color: #000000;
}
.order-history-dialog-content-title2 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 126.5% !important;
  /* or 30px */
  color: #58a30a;
  cursor: pointer;
}
.pointers {
  cursor: pointer;
}
.order-history-dialog-map-address-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px !important;
}
.order-history-dialog-map-address-des {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 101% !important;
  padding-left: 10px;
}
.order-histroy-dialog-add-container {
  border: 1px solid black;
}
.order-history-dialog-map-price-total {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 126.5% !important;
  /* or 30px */
  color: #58a30a;
  padding-left: 10px;
}
.order-history-dialog-btn {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px;
  float: right;
}
.radio {
  position: relative;
}

.radio input {
  width: auto;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 4px 25px;
  border: 1px solid black;
  color: #000000;
  font-size: 14px;
  transition: all 100ms linear;
}

.radio input:checked {
  border: 1px solid #58a30a;
  color: #58a30a;
}

.radio input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}
.careers-top-boder {
  border-top: 2px solid gray;
}

/* Login */
.login-page-main-container {
  padding-top: 150px;
}
.main-top-container {
  padding-top: 80px;
  background-color: white;
}
.top-container {
  padding-top: 80px;
  background-color: white;
}
.login-title {
  width: 100%;
  text-align: center;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 126.5%;
  padding-top: 20px;
}

.login-sub-container {
  width: 100%;
  display: flex;
  justify-content: center !important;
  padding-top: 20px;
  padding-bottom: 60px;
}
.login-form-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding: 30px 0px;
  /* text-align: center !important; */
}

.login-form {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-bottom: 50px;
}
.login-forgot-text {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #58a30a;
}
.login-forgot-link-span {
  width: 100% !important;
  text-align: right;
  margin-bottom: 50px;
}
.login-forgot-link {
  text-decoration: none !important;
}
.login-btn {
  height: 40px !important;
  min-width: 150px !important;
  background: #173470 !important;
  color: white;
  border-radius: 50px !important;
  text-transform: none !important;
}
.login-signup-text {
  font-family: 'Poppins' !important;
  font-style: normal;
  text-align: center;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000;
}

.login-signup-link {
  text-decoration: none !important;
  color: rgba(78, 159, 11, 1);
}
.login-google {
  height: 40px;
  width: 40px;
  padding: 0px 10px;
}
.login-other-connect-span {
  margin: 30px 0px;
}
.login-other-text {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.47) !important;
}
.login-google-span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgot-password-text {
  font-weight: 400 !important;
  font-size: 15px !important;
  width: 50%;
}

.forgot-subtitle-text-span {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* wishlistCard */

.wishlist-prod-img {
  width: 100% !important;
  height: 150px;
}

.wishlist-card {
  width: 100% !important;
  display: flex;
  flex-direction: row;
}

.wishlist-prod-grid-item1 {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  padding-left: 20px !important;
}
.wishlist-prod-grid-item2 {
  padding-top: 10px !important;
}
.wishlist-prod-grid-item3 {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 20px 0px !important;
}
.wishlist-card-prod-title {
  font-family: 'Poppins' !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.wishlist-card-prod-price {
  font-family: 'Poppins' !important;
  color: #58a30a;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.wishlist-card-prod-discount {
  font-family: 'Poppins' !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.wishlist-card-prod-msg {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #173470;
}
.wishlist-cart-btn-container {
  text-align: center;
}
.wishlist-cart-btn {
  height: 35px !important;
  width: 85px !important;
}
.wish-list-card:hover {
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.25);
}
.single-prod-main-container {
  margin-top: 150px !important;
  display: flex !important;

  align-items: flex-start !important;
}
.prod-image-main {
  width: 100%;
  height: 350px;
}

.singleProdMainContainer {
  display: flex;
  margin-top: 100px;
  padding: 20px 60px;
}

.prod-divider {
  margin: 25px 0px !important;
  height: 2px;
  background: #000000 0.5;
}

.prod-image-small {
  width: 70px;
  border-radius: 5px;
  /* padding-right: 10px; */
  /* padding: 0px 10px; */
}

.prod-image-small-container {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  /* margin-bottom: 40px; */
}
.single-prod-title {
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
  font-size: 25px !important;
  font-style: normal;
  color: black;
  /* line-height: 5px; */
}

.single-pro-price {
  color: #58a30a;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 35px !important;
}
.space-between-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 5px; */
  flex-wrap: wrap;
}
.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-container-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.row-container-wrap-chat {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.row-simple-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.row-simple-container-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
}
.acc-btn,
.acc-btn1 {
  background: #d9d9d9 !important;
  color: black !important;
  font-size: 18px !important;
  padding: 0px !important;
  min-width: 30px !important;
  min-height: 25px !important;
  box-shadow: none !important;
  /* margin: 0px 13px !important; */
}

.acc-btn1 {
  background: none !important;
}
.prod-chat-btn {
  display: flex !important;
  flex-direction: column !important;
}

.prodChatBtnText {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: 'Poppins' !important;
}

.prodBtnRow {
  display: flex;
}

.prod-buy-cart-btn {
  border-radius: 30px !important;
  height: 60px !important;
  padding: 0px !important;
  margin: 0px 0px 0px 30px !important;
  min-width: 200px !important;
}
.prod-buy-btn {
  font-size: 10px !important;
  font-family: 'Poppins' !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.prodAddToCartBtn {
  font-size: 8px !important;
  font-family: 'Poppins' !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.prod-page-subtitle {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.prod-des {
  width: 70% !important;
}
.prodpage-text-body {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.prodReviewDivider {
  width: 100% !important;
  margin-top: 10px !important;
}
.prodReviewTitle {
  color: #000000;
  font-family: 'Poppins' !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 0px !important;
  padding-top: 30px !important;
}
.prodComnt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.replyName {
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #4e9f0b;
}

.replyReview {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.replyPaper {
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}

.prodComntImgContainer {
  display: flex;
  padding: 20px 0px;
}

.comntImg {
  margin-right: 20px;
  width: 80px !important;
}

.prodPageSubtitle2 {
  font-weight: 700 !important;
}
.pagination-containerProd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #58a30a !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  height: 50px !important;
  width: 15px !important;
  border-radius: 6px !important;
}
.css-1v2lvtn-MuiPaginationItem-root {
  font-size: 26px !important;
  color: rgba(88, 163, 10, 1) !important ;
}

.writeReviewContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 30px 0px !important;
}

.writeReviewBtn {
  width: 180px !important;
  height: 48px !important;
  background: #000000 !important;
  border-radius: 30px !important;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  text-transform: none !important;
  font-size: 14px !important;
}

.reviewUploadBtn {
  font-family: 'Poppins' !important;
  background: #d9d9d9 !important;
  text-transform: none !important;
  width: 179px !important;
  height: 40px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.uploadImagTitle {
  font-family: 'Poppins' !important;
  margin-top: 30px !important;
  font-size: 14px !important;
}

.review-form-title {
  font-family: 'Poppins' !important;
  font-size: 22px !important;
  padding-bottom: 10px;
}

.JoyCheckbox-colorNeutral {
  color: gray !important;
}

.Joy-checked.JoyCheckbox-colorPrimary {
  color: red !important;
}
svg.JoySvgIcon-root {
  display: none;
}

.check-out-main-container {
  padding-top: 200px !important;
  background: #f5f5f5 !important;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.checkout-table-cell {
  border: none !important;
  padding: 10px 20px !important;
}

.checkout-table-cells {
  border: none !important;
  padding: 10px 0px !important;
}

.checkout-form-box {
  height: auto !important;
  background: white;
  margin-left: 10px !important;
  padding: 15px !important;
  margin-bottom: 30px !important;
}
.checkout-form-title {
  width: 100%;
  text-align: center;
  background: #173470;
  height: 40px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px !important;
}

.checkout-form-title-text {
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 14px;
  color: #ffffff;
}

.checkout-form-input {
  width: 100% !important;
  border: 2px solid black;
  padding: 11px;
  margin: 4px 0px;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 14px;
}
.checkout-form-details-text {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  padding-bottom: 10px !important;
}

.checkout-form-subtitle {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  padding: 10px 0px;
}

.checkout-form-subtitle-detail {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.5);
}
.checkout-form-subtitle-detail2 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
}
.checkout-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.checkout-form-divider {
  background-color: rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 10px !important;
}
.checkout-btn {
  text-transform: none !important;
  font-family: 'Poppins' !important;
}

.table-cell-img {
  display: flex !important;
  flex-wrap: wrap;
}

.atc-header-text-font1 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.atc-header-text-font2 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding-left: 10px;
  align-self: flex-start;
}
.atc-header-text-font3 {
  color: gray;
  text-decoration: line-through;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.atc-header-text-font4 {
  color: red;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.atc-header-text-font5 {
  color: #4e9f0b;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.atc-box-img {
  height: 120px;
  width: 120px;
  /* padding-left: 10px; */
}
.gray-color {
  color: gray;
}
.atc-col-container {
  display: flex;
  flex-direction: column !important;
}
.atc-btn,
.atc-btn1 {
  background: #d9d9d9 !important;
  color: black !important;
  font-size: 12px !important;
  padding: 0px !important;
  min-width: 30px !important;
  min-height: 25px !important;
}
.row-space-around-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.category-detail-title {
  color: black;
  font-family: 'Poppins' !important;
  font-weight: 800 !important;
  font-size: 20px !important;
}
.row-center-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.row-justify-center-flex {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
.pm-tab-img {
  height: 50px;
}
.pm-tab-text {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  padding-top: 10px;
}
.pm-tab-content-text {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-top: 10px;
}
.pm-col-container {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.vendor-login-page-main-container {
  padding-top: 150px;
  background: #f5f5f5 !important;
  display: flex !important;
  justify-content: center;
  padding-bottom: 50px !important;
}

.vendor-login-white-container {
  background: white !important;
  padding: 60px 50px !important;
}

.vendor-login-title {
  padding-bottom: 30px;
}

.vendor-signup-page-main-container {
  padding-top: 150px;
  background: #f5f5f5 !important;
  /* display: flex !important; */
  justify-content: center;
  padding-bottom: 50px !important;
}

.vendor-singnup-form-row {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.vendor-signup-input-item {
  padding: 0px 10px;
}

.vendor-signup-btn {
  margin: 50px 0px !important;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
  text-align: center;
}
.continue-shopping a,
.start-shopping a {
  color: #4e9f0b;
  text-decoration: none;
}
.checkout-address-type-btn {
  background: white !important;
  padding: 10px 40px !important;
  margin: 5px 10px;
  color: black !important;
  /* border: 2px solid black; */
  font-family: 'Poppins' !important;
}
.css-9zhz34-JoyRadio-action {
  color: #58a30a !important;
  border: 2px solid #58a30a !important;
  border-radius: 0 !important;
}
.row-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.confirm-order-header-text {
  color: #4e9f0b;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
}
.co-header-text-font {
  font-size: 20px !important;
  padding-left: 5px;
}
.co-header-text-font2 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.co-header-text-font3 {
  color: #4e9f0b;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 25px !important;
}
.co-header-text-font4 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.co-header-text-font5 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
}
.co-header-text-font6 {
  color: gray;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.co-box-border {
  border: 1px solid black;
  padding: 30px;
}
.co-content-bottom-border {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
.row-space-between-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.co-box-img {
  height: 120px;
  padding-left: 10px;
}
.co-view-order-btn {
  margin-top: 30px !important;
  float: right !important;
  margin-right: 50px !important;
}
.co-des-text {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.sliderimg {
  width: auto;
  height: 150px;
}
.sliderimg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-box {
  filter: drop-shadow(0px 0px 1px #a7a7a7);
}
.about-video-player {
  width: 80%;
  height: 480px;
}
.contact-font {
  font-family: 'Poppins' !important;
  font-size: 14px;
  font-weight: 400;
}
.contact-font1 {
  font-family: 'Poppins' !important;
  font-size: 16px;
  font-weight: 400;
}
.bottomright {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 25%;
  z-index: 2;
  height: 60%;
  border: thin solid gray;
  background-color: #f7f5f5;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.chat-header-icon {
  font-size: 30px !important;
}
.chat-content1-icon {
  color: #4e9f0b;
}
.chat-content-text1 {
  background-color: #4e9f0b;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold !important;
  text-align: left;
}
.chat-content-text2 {
  background-color: #e7e7e7;
  color: black;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold !important;
  text-align: left;
  width: 100%;
}
.chat-input {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 25%;
}
.chat-header-icon1 {
  font-size: 20px !important;
  color: #000000;
}
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* Thank you page */
.wrapper-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper-2 {
  padding: 30px;
  text-align: center;
}
h1 {
  font-family: 'Kaushan Script', cursive;
  font-size: 4em;
  letter-spacing: 3px;
  color: #4e9f0b;
  margin: 0;
  margin-bottom: 20px;
}
.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 1px;
}
.go-home {
  color: #fff;
  background: #4e9f0b;
  border: none;
  padding: 10px 50px;
  margin: 30px 0;
  border-radius: 30px;
  text-transform: capitalize;
  box-shadow: 0 10px 16px 1px #caefab;
  cursor: pointer;
}
.footer-like {
  margin-top: auto;
  background: #caefab;
  padding: 6px;
  text-align: center;
}
.footer-like p {
  margin: 0;
  padding: 4px;
  color: #4e9f0b;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 1px;
}
.footer-like p a {
  text-decoration: none;
  color: #4e9f0b;
  font-weight: 600;
}
.header-top-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}
@media (min-width: 250px) {
  .breadcrumb-text__title {
    font-size: 30px !important;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 10px;
    line-height: 10px;
  }
  .breadcrumb-text__nav {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -30px !important;
    list-style: none;
    margin-right: 20px;
  }
  .breadcrumb-text__nav li {
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }
  h1 {
    font-size: 4.5em;
  }
  .go-home {
    margin-bottom: 20px;
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .featured-products-header {
    /* background-image: url("../Assets/bg-img.png"); */
    background-repeat: no-repeat;
    padding: 50px 40px;
    /* display: flex; */
    background-size: 100% 100%;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .breadcrumb-text__title {
    font-size: 30px !important;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 10px;
    line-height: 30px !important;
  }
}
@media (min-width: 600px) {
  .breadcrumb-text__title {
    font-size: 60px !important;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 10px;
    line-height: 20px;
  }
  .breadcrumb-text__nav {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -30px !important;
    list-style: none;
    margin-right: 20px;
  }
  .breadcrumb-text__nav li {
    font-size: 20px !important;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .wrapper-1 {
    height: initial;
    max-width: 620px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px #dff4cf;
  }
}
.usp-container {
  background-color: #173470;
  color: #ffffff;
  margin: 5px;
  height: 180px;
}
.breadcrumb-text {
  text-align: right;
  position: relative;
  /* z-index: 2; */
}
.breadcrumb-text__title {
  font-size: 80px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 10px;
  line-height: 70px;
}
.breadcrumb-text__nav {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: -70px;
  list-style: none;
  margin-right: 20px;
}
.breadcrumb-text__nav li {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
.breadcrumb-text__nav li a {
  text-decoration: none;
  color: #fff;
}
.search-card-product {
  padding: 10px;
  cursor: pointer;
}
.search-card-product:hover {
  background-color: rgb(240, 240, 240);
}
.profile-navbar-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding-left: 0px;
  padding-top: 8px;
}
.contact-list-item:hover {
  background-color: transparent !important;
  font-weight: 800 !important;
  color: #0a1c41 !important;
  font-size: 30px !important;
}
.home-alert-container {
  background-image: url(/src/client/Assets/alert-background.png) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-color: transparent !important;
  padding: 0px !important;
}
.css-154lg22-MuiPaper-root-MuiDialog-paper {
  max-width: 700px !important;
  max-height: calc(100% - 200px) !important;
}
