.green-job-btn {
  background-color: #8cb505;
  color: white;
}
.green-job-btn:hover {
  background-color: #709203;
  color: white;
}
.green-job-outline-btn {
  color: #8cb505;
}
.green-job-outline-btn:hover {
  border: 1px solid #8cb505;
  color: #8cb505;
  background-color: transparent;
}
.job-card {
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-decoration: none;
  color: #000;
}
.job-card:hover {
  background-color: rgba(217, 217, 217, 0.716);
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.job-card-deatils {
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-decoration: none;
  color: #000;
}
.job-card-deatils:hover {
  background: rgba(217, 217, 217, 0.28);
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-left: 2px solid rgba(0, 0, 0, 1);
}
.job-card-deatils-active {
  background: rgba(217, 217, 217, 0.28);
  padding: 10px;
  cursor: pointer;
  padding-bottom: 20px;
  border-radius: 10px;
  text-decoration: none;
  color: #000;
  border-left: 2px solid rgba(0, 0, 0, 1);
}
.job-title-card {
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-decoration: none;
  color: #000;
}
.css-1ywbgsr-MuiBadge-badge {
  background-color: #8cb505 !important;
}
.MuiBadge-badge {
  background-color: #8cb505 !important;
  color: #000 !important;
}
