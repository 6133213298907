.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}
.product-order-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.chat-container {
  border: 1px solid rgba(11, 10, 10, 0);
  height: 800px;
}
.headerRow {
  max-height: 60;
  z-index: 5;
}
.messageContainer {
  height: '100%';
  display: 'flex';
  align-content: 'flex-end';
}
.message-box {
  max-height: 280px;
  height: 280px;
  overflow-y: auto !important;
}
.messagesRow {
  height: 100%;
}
.messagesRow .MuiTypography-body1 {
  font-weight: bold !important;
}
.messageBubble {
  padding: 10px;
  border: 1px solid white;
  background-color: white;
  border-radius: 0 10px 10px 10px;
  box-shadow: -3px 4px 4px 0px rgba(0, 0, 0, 0.08);
  margin-top: 8px;
  max-width: 40em;
}
.listItem {
  display: 'flex';
  width: '100%';
}
.gallery-image-container {
  position: relative;
  top: 0;
  left: 0;
}
.gallery-image {
  position: relative;
  top: 0;
  left: 0;
}
.gallery-checkbox-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}
.custom-product-card {
  background-color: #173470;
  color: white;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 5px 20px;
}
.custom-product-card:hover {
  background-color: #173470;
  color: white;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 5px 20px;
}
.custom-product-detail {
  background-color: #173470;
  color: white;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 10px 30px;
}
.custom-product-detail:hover {
  background-color: #173470;
  color: white;
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 10px 30px;
}
.remove-accordian-margin .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}
.custom-product-title {
  font-size: medium;
  font-weight: bold;
}
.custon-product-checkbox {
  background-color: inherit;
  color: black;
  border: thin solid black;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.custon-product-checkbox-status {
  font-size: 12px;
  font-weight: bold;
  background-color: #58a30a;
  color: white;
  padding: 4px;
  margin: 0px;
}
.custon-product-checkbox-text {
  font-size: 14px;
  padding: 10px;
  margin: 0px;
}
.custon-product-checkbox:hover {
  background-color: inherit;
  color: #173470;
  border: 2px solid #173470;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.custon-product-border {
  background-color: inherit;
  color: #173470;
  border: 2px solid #173470;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.custom-prod-image-main {
  width: auto;
  height: 400px;
}
.custom-product-ribbon {
  height: auto;
  width: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #58a30a;
  color: white;
  right: 0;
  position: fixed;
  top: 30%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 1999;
}
.custom-product-ribbon .custon-product-checkbox-text {
  font-size: 12px;
  padding: 4px;
  margin: 0px;
}
.custom-product-ribbon .custon-product-checkbox-status {
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
  color: white;
  padding: 4px;
  margin: 0px;
}
.breadcrumb-text-custom-product {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.breadcrumb-text-custom-product li {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: black;
  text-transform: uppercase;
  font-weight: 500;
}
.breadcrumb-text-custom-product li a {
  text-decoration: none;
  color: black;
}
.carousel .slide .legend {
  bottom: 10px !important;
  opacity: 1;
  padding: 5px;
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: left;
}
.legend h4 {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  padding-top: 5px;
  padding-left: 5px;
}
.legend p {
  margin: 0px;
  padding: 5px;
}
.carousel * {
  border-radius: 10px !important;
  cursor: pointer;
}
.card-blog {
  --blur: 16px;
  --size: clamp(300px, 50vmin, 600px);
  width: 100%;
  height: 500px;
  aspect-ratio: 4 / 3;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  color: #000;
  transform: translateZ(0);
}

.card-blog__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
  transition: transform 0.2s;
}

.card-blog__footer {
  text-align: center;
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  color: white;
  display: grid;
  grid-template-row: auto auto;
  gap: 0.5ch;
  background: hsla(0, 0%, 0%, 0.238);
  -webkit-backdrop-filter: blur(var(--blur));
  backdrop-filter: blur(var(--blur));
  align-content: center;
}
